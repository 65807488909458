import React from "react";
import { BookingContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { setReduxWallet } from "store/reducers/walletReducer";
import QRCode from "react-qr-code";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";
import { MyPointWidget, MemberSwitchTypeWidget } from "widgets";
import { Buttons, Displays, Headers, Icons } from "components";

class BookingContainer extends React.Component {
  state = {
    isLoading: true,
    currentCase: 1,
    isSelected: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux } = this.props;
    let res = await userService.GET_MY_BOOKING_LIST(
      `?member_type=${authenRedux.member_type_active}`
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleNextCase = () => {
    this.setState((prevState) => ({
      currentCase: prevState.currentCase === 4 ? 1 : prevState.currentCase + 1, // Loop back to 1 after case 4
    }));
  };

  handleCancelBooking = async () => {
    const { i18n } = this.props;
    AlertControl.show({
      title:
        i18n.language === "en" ? "Confirm Cancellation" : "ยืนยันการยกเลิก",
      description:
        i18n.language === "en"
          ? "Do you want to cancel the booking?"
          : "คุณต้องการที่จะยกเลิกการจอง ใช่หรือไม่",
      btnLabel1: i18n.language === "en" ? "Back" : "กลับ",
      btnLabel2: i18n.language === "en" ? "Confirm" : "ตกลง",

      onEvent2: async () => {
        const { data } = this.state;
        this.setState({
          isBgLoading: true,
        });
        let params = {
          booking_id: data._id,
        };
        let res = await userService.POST_BOOKING_CANCEL(params);
        if (res && res.status === 200) {
          this.fetchData();
          toast.success("ยกเลิกการจองสำเร็จ");
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("ยกเลิกการจองไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่");
        }
      },
    });
  };

  handleButtonClick = () => {
    this.setState({ isSelected: false });
  };

  renderContent = (e) => {
    const { data } = this.state;
    const { i18n } = this.props;
    switch (e.status) {
      case "1":
        return (
          <>
            <div className="status_detail">
              <div className="title">
                {i18n.language === "en"
                  ? "Booking Detail "
                  : "รายละเอียดการจองเวลา"}
              </div>
              <div className="detail_status">
                <div className="status yellow">Waiting</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">
                    {i18n.language === "en"
                      ? "Musical Instrument"
                      : "กลุ่มเครื่องดนตรี"}
                  </div>
                  <div className="title_detail">
                    {" "}
                    {i18n.language === "en" ? "Product" : "เครื่อง"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Model" : "รุ่น (ถ้ามี)"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Date" : "วันและเวลา"}
                  </div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    {i18n.language === "th" ? "เวลา" : "Time"} {e.start_time} -
                    {e.end_time} {i18n.language === "th" && "น."}
                  </div>
                </div>
              </div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={i18n.language === "en" ? "Cancel" : "ยกเลิกการจอง"}
                  onClick={this.handleCancelBooking}
                />
              </div>
            </div>
          </>
        );
      case "2":
        return (
          <>
            <div className="status_detail">
              <div className="title">
                {i18n.language === "en"
                  ? "Booking Detail "
                  : "รายละเอียดการจองเวลา"}
              </div>
              <div className="detail_status">
                <div className="status green">Approved</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">
                    {i18n.language === "en"
                      ? "Musical Instrument"
                      : "กลุ่มเครื่องดนตรี"}
                  </div>
                  <div className="title_detail">
                    {" "}
                    {i18n.language === "en" ? "Product" : "เครื่อง"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Model" : "รุ่น (ถ้ามี)"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Date" : "วันและเวลา"}
                  </div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    {i18n.language === "th" ? "เวลา" : "Time"} {e.start_time} -
                    {e.end_time} {i18n.language === "th" && "น."}
                  </div>
                </div>
              </div>
              <div className="remark">
                {" "}
                {i18n.language === "en"
                  ? "In case you are unable to arrive on time, please cancel your reservation to maintain your right for future bookings."
                  : "*ในกรณีที่ท่านไม่สามารถมาตามเวลาได้ กรุณายกเลิกการจอง เพิ่อรักษาสิทธิ์ของท่านในการจองครั้งต่อไป"}
              </div>
              <div className="qr_sec">
                {/* <img src="/assets/images/mockups/image-qr.png" alt="qr" /> */}
                <QRCode
                  value={`showroom,${data.customer_ref_id},${data._id}`}
                  size={190}
                />
              </div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={i18n.language === "en" ? "Cancel" : "ยกเลิกการจอง"}
                  onClick={this.handleCancelBooking}
                />
              </div>
            </div>
            {/* <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_cancel
                  label={"ไปหน้ายังไม่ได้ลงทำเบียน"}
                  onClick={this.handleNextCase} // Change back to Case 1 when clicked
                />
              </div> */}
          </>
        );
      case "3":
        return (
          <>
            <div className="status_detail">
              <div className="title">
                {i18n.language === "en"
                  ? "Booking Detail "
                  : "รายละเอียดการจองเวลา"}
              </div>
              <div className="detail_status">
                <div className="status orange">Rejected</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">
                    {i18n.language === "en"
                      ? "Musical Instrument"
                      : "กลุ่มเครื่องดนตรี"}
                  </div>
                  <div className="title_detail">
                    {" "}
                    {i18n.language === "en" ? "Product" : "เครื่อง"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Model" : "รุ่น (ถ้ามี)"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Date" : "วันและเวลา"}
                  </div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    {i18n.language === "th" ? "เวลา" : "Time"} {e.start_time} -
                    {e.end_time} {i18n.language === "th" && "น."}
                  </div>
                </div>
              </div>
              <div className="remark">*{e.reason_reject}</div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={"ลงทะเบียนจองใหม่"}
                  onClick={() => {
                    this.setState({
                      data: false,
                    });
                  }}
                />
              </div>
            </div>
            {/* <div className="btn_wrap">
              <Buttons.BgStandard
                className="btn"
                theme_cancel
                label={"ไปหน้าลงทะเบียนแล้ว (Case 3)"}
                onClick={this.handleNextCase}
              />
            </div> */}
          </>
        );

      default:
        break;
    }
  };

  render() {
    const { isBgLoading, isLoading, data, isSelected } = this.state;
    const { i18n } = this.props;
    return (
      <BookingContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={"25px"}
          icon={<Icons.SubMenuBooking />}
          topLabel={"Musical Instrument Test"}
          bottomLabel={"Showroom Visit Booking"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            {isSelected ? (
              <div className="section">
                <div className="activity_wrap">
                  <img
                    className="act_img"
                    alt="activity"
                    src={"/assets/images/backgrounds/booking-img.jpg"}
                  />
                </div>
                <div className="status_wrap">
                  <div className="title">
                    {i18n.language === "en"
                      ? "Welcome to the Yamaha Flagship Store,Bangkok & YDACC (Yamaha Digital Audio Creative Center)"
                      : "ยินดีต้อนรับสู่ Yamaha Flagship Store, Bangkok & YDACC (Yamaha Digital Audio Creative Center)"}
                  </div>
                  <div className="detail">
                    {i18n.language === "en"
                      ? `To book your music instrument test or showroom visit, please click "Register" and fill out the form with your details, selecting your preferred time slot. Once your form is submitted, our team will update you on the status of your booking.`
                      : `เพื่อทำการจองการทดลองเครื่องดนตรีหรือเยี่ยมชมโชว์รูม กรุณาคลิกที่ "ลงทะเบียน" และกรอกข้อมูลพร้อมเลือกช่วงเวลาที่สะดวก หลังจากที่ท่านส่งข้อมูลแล้ว ทีมงานจะอัพเดตสถานะการจอง ให้ท่านทราบ`}
                  </div>
                  <div className="detail bd">
                    {i18n.language === "en" ? `Location:\n` : `ที่อยู่:\n`}
                    <span>
                      {i18n.language === "en"
                        ? `Siam Patumwan House, M Floor \n414 Phayathai Road, Wang Mai Sub District, \nPathum Wan District, Bangkok, \nthailand, 10330`
                        : `อาคารสยามปทุมวัน เฮ้าส์, ชั้น M \n414 ถนนพระรามที่ 1 แขวงวังใหม่ เขตปทุมวัน \nกรุงเทพฯ 10330`}
                    </span>
                  </div>

                  <div className="detail bd">
                    {i18n.language === "en" ? `Google Map:` : `Google Map:`}{" "}
                    <a
                      href="https://g.co/kgs/pZLf7Xc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://g.co/kgs/pZLf7Xc
                    </a>
                  </div>
                </div>
                {!data || data.status === "4" ? (
                  <div className="btn_wrap">
                    <Buttons.BgStandard
                      className="btn"
                      theme_standard
                      label={
                        i18n.language === "en" ? "Booking" : "ลงทะเบียนจอง"
                      }
                      onClick={() => this.handleButtonClick(1)}
                    />
                  </div>
                ) : (
                  this.renderContent(data)
                )}
              </div>
            ) : (
              <>
                <div className="desc">
                  {i18n.language === "en"
                    ? "Please provide the following details so that we can prepare the musical instruments for testing or arrange a suitable visit based on your objectives"
                    : "กรุณากรอกรายละเอียด เพื่อเราสามารถเตรียมเครื่องดนตรีสำหรับทดลองหรือสถานที่สำหรับการเยี่ยมชม"}
                </div>
                <MemberSwitchTypeWidget
                  type="3"
                  onChange={() => {
                    this.props.router.navigate(ROUTE_PATH.BOOKING_DETAIL);
                  }}
                />
              </>
            )}
          </>
        )}
      </BookingContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profile"])(withRouter(BookingContainer)));
