import { useTranslation } from "react-i18next";
import { CelendarWidgetStyled } from "./styled";
import moment from "moment";

const CelendarWidget = ({
  startDate,
  timeSlotData,
  holidayData,
  onClickPrevWeek,
  onClickNextWeek,
}) => {
  const { i18n } = useTranslation([""]);

  const _getStatusColor = (status) => {
    switch (status) {
      case "1":
        return "#A694B8";
      case "2":
        return "#EFC788";
      case "3":
      case "4":
        return "#FF9C96";
      default:
        return "#A694B8";
    }
  };

  const _renderDay = () => {
    const startOfWeek = moment(startDate, "YYYY-MM-DD").startOf("week");
    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="day_cell">
          <div>{startOfWeek.format("ddd")}</div>
          <div>{startOfWeek.format("D")}</div>
        </div>
      );
      startOfWeek.add(1, "day");
    }
    return days;
  };

  const _renderCalendar = () => {
    let days = [];
    TIME_DATA.slice(1, TIME_DATA.length).forEach((e) => {
      const startOfWeek = moment(startDate, "YYYY-MM-DD").startOf("week");
      for (let i = 0; i < 7; i++) {
        // HOLIDAY
        if (
          holidayData
            .map((f) => moment(f.date).format("YYYY-MM-DD"))
            .includes(startOfWeek.format("YYYY-MM-DD"))
        ) {
          days.push(
            <div
              className="data_cell"
              style={{ background: _getStatusColor("3") }}
            >
              {/* {startOfWeek.format("D")} */}
            </div>
          );
        } else {
          let findTimeSlot = timeSlotData.find(
            (f) =>
              moment(f.date).format("YYYY-MM-DD") ===
                startOfWeek.format("YYYY-MM-DD") &&
              f.start_time === e.value.startTime &&
              f.end_time === e.value.endTime
          );
          days.push(
            <div
              className="data_cell"
              style={{
                background: _getStatusColor(findTimeSlot?.status || "1"),
              }}
            >
              {/* {startOfWeek.format("D")} */}
            </div>
          );
        }
        startOfWeek.add(1, "day");
      }
    });

    return days;
  };

  return (
    <CelendarWidgetStyled>
      <div className="cd_header_row">
        <div className="top_left">{moment().format("MMMM YYYY")}</div>
        <div className="action_next_prev_block">
          <div className="top_right" onClick={onClickPrevWeek}>
            {i18n.language === "en" ? "< Previous" : `< ย้อนกลับ`}
          </div>
          <div className="top_right" onClick={onClickNextWeek}>
            {i18n.language === "en" ? "Next >" : `อาทิตย์ถัดไป >`}
          </div>
        </div>
      </div>
      <div className="cd_body">
        <div className="table_left_column">
          {TIME_DATA.map((e, index) => (
            <div
              key={index}
              className={`time_cell ${index === 0 ? "f_16" : ""}`}
            >
              {e.label}
            </div>
          ))}
        </div>
        <div className="table_right_column">
          <div className="day_row">{_renderDay()}</div>
          <div className="data_cell_block">{_renderCalendar()}</div>
          <div className="bottom">
            <div className="status_detail">
              <div className="box full" />
              <div className="status_title">
                {i18n.language === "en" ? "Unavailable" : "ไม่ว่าง"}
              </div>
            </div>
            <div className="status_detail">
              <div className="box almost" />
              <div className="status_title">
                {i18n.language === "en" ? "Almost full" : "ใกล้เต็ม"}
              </div>
            </div>
            <div className="status_detail">
              <div className="box available" />
              <div className="status_title">
                {i18n.language === "en" ? "Available" : "ว่าง"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CelendarWidgetStyled>
  );
};

const TIME_DATA = [
  { label: "Time" },
  { label: "10.00\n11.00", value: { startTime: "10.00", endTime: "11.00" } },
  { label: "11.00\n12.00", value: { startTime: "11.00", endTime: "12.00" } },
  { label: "13.00\n14.00", value: { startTime: "13.00", endTime: "14.00" } },
  { label: "14.00\n15.00", value: { startTime: "14.00", endTime: "15.00" } },
  { label: "15.00\n16.00", value: { startTime: "15.00", endTime: "16.00" } },
  { label: "16.00\n17.00", value: { startTime: "16.00", endTime: "17.00" } },
  { label: "17.00\n18.00", value: { startTime: "17.00", endTime: "18.00" } },
];

export default CelendarWidget;
