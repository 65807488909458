import React, { useState } from "react";
import { VisitorFollowerFormStyled } from "./styled";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const VisitorFollowerForm = ({ onSubmit }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const schema = yup.object().shape({
    rows: yup.number().min(0, "กรุณากรอกจำนวนผู้ติดตามที่เป็นเด็ก"),
    followers: yup.array().of(
      yup.object().shape({
        name: yup.string().required("ชื่อจำเป็นต้องกรอก"),
        surname: yup.string().required("นามสกุลจำเป็นต้องกรอก"),
        birthDate: yup.string().required("วันเกิดจำเป็นต้องกรอก"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "followers",
  });

  const add = () => {
    append({ name: "", surname: "", birthDate: "" });
    setIsSubmitted(true);
  };

  return (
    <VisitorFollowerFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title">เพิ่มผู้ติดตามที่เป็นเด็ก </div>
        {fields.map((e, i) => (
          <div key={e.id} className="item_wrap">
            <h4>คนที่ {i + 1}</h4>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder="ชื่อ"
                    errors={errors.followers?.[i]?.name?.message}
                  />
                )}
                name={`followers[${i}].name`}
                defaultValue={e.name}
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder="นามสกุล"
                    errors={errors.followers?.[i]?.surname?.message}
                  />
                )}
                name={`followers[${i}].surname`}
                defaultValue={e.surname}
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder="วันเกิด"
                    errors={errors.followers?.[i]?.birthDate?.message}
                  />
                )}
                name={`followers[${i}].birthDate`}
                defaultValue={e.birthDate}
              />
            </div>
            <div className="btn_delete_wrap">
              <button
                className="btn_delete"
                type="button"
                onClick={() => remove(i)}
              >
                ลบ
              </button>
            </div>
          </div>
        ))}
        {fields.length < 5 && (
          <div className="btn_submit_wrap">
            <Buttons.BgStandard
              type="button"
              label="เพิ่มผู้ติดตาม"
              onClick={add}
            />
          </div>
        )}
        {isSubmitted && fields.length > 0 && (
          <div className="btn_submit_wrap right">
            <Buttons.BgStandard type="submit" label={"บันทึก"} />
          </div>
        )}
      </form>
    </VisitorFollowerFormStyled>
  );
};

VisitorFollowerForm.propTypes = {};

export default VisitorFollowerForm;
