import styled from "styled-components";

export const VisitorFollowerFormStyled = styled.div`
  padding: 20px;
  color: ${({ theme }) => theme.COLORS.PURPLE_1};
  .title {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    margin-bottom: 20px;
  }
  .item_wrap {
    margin-bottom: 20px;
    .input_wrap {
      margin-bottom: 15px;
    }
    .btn_delete_wrap {
      display: flex;
      justify-content: flex-end;
      .btn_delete {
        color: ${({ theme }) => theme.COLORS.RED_1};
        background: none;
        cursor: pointer;
      }
    }
  }
  .btn_submit_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    &.right {
      justify-content: flex-end;
    }
    .btn {
      width: 150px;
      margin-bottom: 50px;
    }
  }
`;
