import React, { useEffect, useState, useRef } from "react";
// import PropTypes from 'prop-types'
import { LoadingStyled } from "./styled";

const Loading = ({ visible }) => {
  let loadingRef = useRef();
  const [_isLoading, _setIsLoading] = useState(false);

  useEffect(() => {
    _setIsLoading(visible ? true : false);
    if (visible) {
      loadingRef.current = setTimeout(() => {
        _setIsLoading(false);
      }, 15000);
    } else {
      loadingRef.current && clearTimeout(loadingRef.current);
    }
    return () => {
      _setIsLoading(false);
      clearInterval(loadingRef.current);
    };
  }, [visible]);

  return (
    <LoadingStyled visible={_isLoading}>
      {/* <Icons.Loading /> */}
      <img
        className="yamaha_loading"
        alt="yamaha loading"
        src="/assets/images/icons/note-gif-33.gif"
      />
    </LoadingStyled>
  );
};

Loading.propTypes = {};

export default Loading;
