import React from "react";
import { ScanQRCodeFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  //  Buttons,
  Inputs,
} from "components";

const ScanQRCodeForm = ({ onSuccessScan, onSubmit }) => {
  const schema = yup.object().shape({
    // serial_no: yup.string().required('New password is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation(["common"]);

  return (
    <ScanQRCodeFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.ScanQRCode
                {...field}
                label={t("Scan QR Code", { ns: "common" })}
                // placeholder={t('test')}
                onSuccessScan={onSuccessScan}
                errors={errors.serial_no?.message}
              />
            )}
            name="serial_no"
            defaultValue=""
          />
        </div>
        {/* <div className="btn_submit_wrap">
          <Buttons.BgStandard
            type="button"
            label="ตกลง"
            // ===== case Activity admin scan ===========
            // ==========================================
            // onClick={() => onSuccessScan("655511e6d742c2335268d3c1,669e186aeb0f3f457a8d6daf,66c393f102a600add2769c06,1,2")}

            // ========= case showroom customer scan ====
            // ==========================================
            // onClick={() => onSuccessScan("/showroom")}

            // ========= case showroom admin scan =======
            // ==========================================
            onClick={() => onSuccessScan("showroom,6486adfc1aeb83a7308e2186,67624901660698fbd98dcae5")}
          />
        </div> */}
      </form>
    </ScanQRCodeFormStyled>
  );
};

ScanQRCodeForm.propTypes = {};

export default ScanQRCodeForm;
