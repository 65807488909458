import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { Mainlayouts } from "components";
import systemRoutes from "./systemRoutes";
import SplashContainer from "containers/SplashContainer";
import LoginAdminContainer from "containers/LoginAdminContainer";
import AdminScanActivityContainer from "containers/AdminScanActivityContainer";
import LoginContainer from "containers/LoginContainer";
import RegisterContainer from "containers/RegisterContainer";
import PreRegisterContainer from "containers/PreRegisterContainer";
import PreLineAuthProductWarrantyContainer from "containers/PreLineAuthProductWarrantyContainer";
import PDPAContainer from "containers/PDPAContainer";
import ProductwarranteeNotLoginContainer from "containers/ProductWarranteeSystem/ProductwarranteeNotLoginContainer";
import ProductWarranteeNotLoginSuccessContainer from "containers/ProductWarranteeSystem/ProductWarranteeNotLoginSuccessContainer";

const Navigations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const locationSplit = location.pathname.split("/");
    if (locationSplit.some((e) => e === "system")) {
      checkToken();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkToken = async () => {
    let res = await userService.POST_CHECK_TOKEN();
    if (res && res.status === 200) {
      // const locationSplit = location.pathname.split('/');
      // switch (locationSplit[locationSplit.length - 1]) {
      //   case 'register':
      //     navigate(ROUTE_PATH.SPLASH);
      //     break;
      //   default:
      //     break;
      // }
    } else {
      const locationSplit = location.pathname.split("/");
      switch (locationSplit[locationSplit.length - 1]) {
        case "login":
          navigate(ROUTE_PATH.LOGIN);
          break;
        default:
          navigate(ROUTE_PATH.SPLASH);
          break;
      }
    }
  };

  return (
    <Routes>
      <Route element={<Mainlayouts.PageLayout />}>
        <Route path={ROUTE_PATH.SPLASH} element={<SplashContainer />} />
        <Route
          path={ROUTE_PATH.PRE_REGISTER}
          element={<PreRegisterContainer />}
        />
        <Route
          path={ROUTE_PATH.NOTIFICATION}
          element={<PreLineAuthProductWarrantyContainer />}
        />
        <Route path={ROUTE_PATH.REGISTER} element={<RegisterContainer />} />
        <Route path={ROUTE_PATH.PDPA} element={<PDPAContainer />} />
        <Route
          path={ROUTE_PATH.LOGIN_ADMIN}
          element={<LoginAdminContainer />}
        />
        <Route
          path={ROUTE_PATH.ACTIVITIES_ADMIN_SCAN}
          element={<AdminScanActivityContainer />}
        />
        <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
        <Route
          path={ROUTE_PATH.PRODUCT_WARRANTEE_NOT_LOGIN_DETAIL}
          element={<ProductwarranteeNotLoginContainer />}
        />
        <Route
          path={ROUTE_PATH.PRODUCT_WARRANTEE_SUCCESS_NOT_LOGIN}
          element={<ProductWarranteeNotLoginSuccessContainer />}
        />
        <Route path="*" element={<SplashContainer />} />
      </Route>
      <Route path={ROUTE_PATH.SYSTEM} element={<Mainlayouts.SystemLayout />}>
        {systemRoutes &&
          systemRoutes.map((e, i) => {
            return !e.children ? (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ) : (
              <Route key={i} path={e.path} element={e.element}>
                {e.children &&
                  e.children.map((f, j) => (
                    <Route
                      key={j}
                      // index={f.index}
                      index
                      path={f.path}
                      element={f.element}
                    />
                  ))}
              </Route>
            );
          })}
      </Route>
    </Routes>
  );
};

export default Navigations;
