import styled from "styled-components";

export const BookingContainerStyled = styled.div`
  padding: 60px 20px 104px 20px;
  .desc {
    width: 80%;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    margin-bottom: 40px;
  }
  .section {
    .activity_wrap {
      margin-bottom: 22px;
      .act_img {
        width: 100%;
        height: auto;
      }
    }

    .status_wrap {
      .title {
        margin-bottom: 20px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
      }
      .detail {
        width: 95%;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.COLORS.PURPLE_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        white-space: pre-wrap;
        &.bd {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
          span {
            font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
          }
        }
      }
    }
    .btn_wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        padding: 10px 20px;
      }
    }
  }
  .status_detail {
    .title {
      margin-bottom: 10px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
    }

    .detail_status {
      width: 100%;
      .status {
        margin-bottom: 20px;
        width: max-content;
        display: flex;
        text-align: center;
        border-radius: 50px;
        padding: 5px 30px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        &.orange {
          background: #fe8917;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
        &.yellow {
          background: #d9c317;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
        &.green {
          background: #328827;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
    .detail {
      margin-left: 10px;
      display: flex;
      .detail_left {
        .title_detail {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
          margin-bottom: 10px;
          text-wrap: nowrap;
        }
      }
      .detail_right {
        margin-left: 20px;
        margin-bottom: 30px;
        .sub_title {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
          margin-bottom: 10px;
        }
      }
    }
    .remark {
      color: ${({ theme }) => theme.COLORS.RED_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      margin-bottom: 10px;
    }
    .qr_sec {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
    .btn_wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .btn {
        padding: 10px 20px;
      }
    }
  }
`;
