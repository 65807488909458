import React, { useState } from "react";
import { BookingStep1FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  CATE_PRODUCT_OPTION_OBJECT,
  INSTUMENT_TYPE_OPTIONS,
} from "utils/functions/productBooking";
import { BOOKING_PURPOSE_OPTIONS } from "utils/functions/booking";
import { Buttons, Inputs } from "components";

const BookingStep1Form = ({ onSubmit, onBack }) => {
  const { i18n } = useTranslation([""]);

  const schema = yup.object().shape({
    purpose: yup.object().nullable().required("required"),
    age_range: yup.object().nullable().required("required"),
    instrument_type: yup.object().nullable().required("required"),
    product: yup.object().nullable().required("required"),
    price_range: yup.object().nullable().required("required"),
    // model_info: yup.string().required("required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [productOptions, setProductOptions] = useState([]);

  return (
    <BookingStep1FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="section">
          <div className="header">
            <p className="title">
              {i18n.language === "en"
                ? "Please provide the following details so that we can prepare the musical instruments for testing or arrange a suitable visit based on your objectives"
                : "กรุณากรอกรายละเอียด เพื่อเราสามารถเตรียมเครื่องดนตรีสำหรับทดลองหรือสถานที่สำหรับการเยี่ยมชม"}
            </p>
            <div className="sub_title">
              {i18n.language === "en"
                ? "1. Select musical instrument or audio equipment"
                : "1. เลือกเครื่องดนตรีหรือเครื่องเสียง"}
            </div>
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  theme_standard
                  options={BOOKING_PURPOSE_OPTIONS.map((e) => ({
                    ...e,
                    label: i18n.language === "en" ? e.labelEn : e.labelTh,
                    value: e.value,
                  }))}
                  placeholder={
                    i18n.language === "en"
                      ? "Purpose of purchase or visit"
                      : "จุดประสงค์ในการเลือกซื้อหรือเข้าชม"
                  }
                  errors={errors.purpose?.message}
                />
              )}
              name="purpose"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={[
                    {
                      label:
                        i18n.language === "en"
                          ? "3 - 12 years old"
                          : "3 - 12 ปี",
                      value: "1",
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "13 - 18 years old"
                          : "13 - 18 ปี",
                      value: "2",
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "19 - 27 years old"
                          : "19 - 27 ปี",
                      value: "3",
                    },
                    {
                      label:
                        i18n.language === "en" ? "25+years old" : "25 ขึ้นไป",
                      value: "4",
                    },
                  ]}
                  placeholder={
                    i18n.language === "en"
                      ? "Choose the age of the music instrument players"
                      : "ระบุอายุของผู้เล่นเครื่องดนตรี"
                  }
                  errors={errors.age_range?.message}
                />
              )}
              name="age_range"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={INSTUMENT_TYPE_OPTIONS.map((e) => ({
                    ...e,
                    label: i18n.language === "en" ? e.labelEn : e.labelTh,
                    value: e.value, // เพิ่ม value
                  }))}
                  placeholder={
                    i18n.language === "en"
                      ? "Choose type of musical instrument"
                      : "เลือกประเภทเครื่องดนตรี"
                  }
                  errors={errors.instrument_type?.message}
                  onChange={(e) => {
                    setValue("product", null);
                    field.onChange(e);
                    setProductOptions(
                      CATE_PRODUCT_OPTION_OBJECT[e.value]?.map((g) => ({
                        ...g,
                        label: i18n.language === "en" ? g.labelEn : g.labelTh,
                        value: g.value,
                      })) || []
                    );
                  }}
                />
              )}
              name="instrument_type"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={productOptions}
                  placeholder={
                    i18n.language === "en"
                      ? "Select product category"
                      : "เลือกกลุ่มสินค้า"
                  }
                  errors={errors.product?.message}
                  disabled={productOptions.length === 0}
                />
              )}
              name="product"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={[
                    {
                      label:
                        i18n.language === "en"
                          ? "0 – 10,000 THB"
                          : "0 – 10,000 บาท",
                      value: { start: 0, end: 10000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "10,000 - 50,000 THB"
                          : "10,000 - 50,000 บาท",
                      value: { start: 10000, end: 50000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "50,001 - 100,000 THB"
                          : "50,001 - 100,000 บาท",
                      value: { start: 50001, end: 100000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "100,001 - 300,000 THB"
                          : "100,001 - 300,000 บาท",
                      value: { start: 100001, end: 300000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "300,001 - 500,000 THB"
                          : "300,001 - 500,000 บาท",
                      value: { start: 300001, end: 500000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "500,001 - 1,000,000 THB"
                          : "500,001 - 1,000,000 บาท",
                      value: { start: 500001, end: 1000000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "More than 1,000,000 THB"
                          : "มากกว่า 1,000,000 บาท",
                      value: { start: 1000001, end: 1000002 },
                    },
                  ]}
                  placeholder={
                    i18n.language === "en"
                      ? "Preferred price range"
                      : "ช่วงราคาที่สนใจ"
                  }
                  errors={errors.price_range?.message}
                />
              )}
              name="price_range"
            />
          </div>
          <div className="input_wrap mb_70">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextArea
                  {...field}
                  theme_question
                  placeholder={
                    i18n.language === "en"
                      ? "Please specify the model or provide additional details"
                      : "ระบุรุ่น หรือ ข้อมูลเพิ่มเติม"
                  }
                />
              )}
              name={`model_info`}
            />
          </div>
          <div className="btn_wrap">
            <Buttons.BgStandard
              theme_standard
              className="btn"
              onClick={onBack}
              label={i18n.language === "en" ? "Back" : "ย้อนกลับ"}
              // disabled={isSubmitting || !isValid}
            />
            <Buttons.BgStandard
              theme_standard
              className="btn"
              type="submit"
              label={i18n.language === "en" ? "Next" : "ต่อไป"}
              // disabled={isSubmitting || !isValid}
            />
          </div>
        </div>
      </form>
    </BookingStep1FormStyled>
  );
};

BookingStep1Form.propTypes = {};

export default BookingStep1Form;
