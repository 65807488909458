export const INSTUMENT_TYPE_OPTIONS = [
  { labelTh: "เปียโน", labelEn: "Piano", value: "1" },
  { labelTh: "ดุริยางค์", labelEn: "Band & Orchestra", value: "2" },
  {
    labelTh: "คอมโบ และ อิเลคโทรนิกส์คีย์บอร์ด",
    labelEn: "Combo & Electronic Keyboard",
    value: "3",
  },
  {
    labelTh: "เครื่องเสียงมืออาชีพ",
    labelEn: "Professional Audio",
    value: "4",
  },
];

export const RENDER_INSTUMENT_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        labelTh: "เปียโน",
        labelEn: "Piano",
      };
    case "2":
      return {
        labelTh: "คอมโบ และ อิเลคโทรนิกส์คีย์บอร์ด",
        labelEn: "Combo & Electronic Keyboard",
      };
    case "3":
      return {
        labelTh: "คอมโบ และ อิเลคโทรนิกส์คีย์บอร์ด",
        labelEn: "Combo & Electronic Keyboard",
      };
    case "4":
      return {
        labelTh: "เครื่องเสียงมืออาชีพ",
        labelEn: "Professional Audio",
      };
    default:
      return {
        labelTh: "",
        labelEn: "",
      };
  }
};

export const CATE_PRODUCT_OPTION_OBJECT = {
  1: [
    { labelTh: "เปียโนอคูสติก", labelEn: "Acoustic Piano", value: "1" },
    { labelTh: "เปียโนไฮบริด", labelEn: "Hybrid Piano", value: "2" },
    { labelTh: "เปียโนไฟฟ้า", labelEn: "Electronic Piano", value: "3" },
  ],
  2: [
    { labelTh: "เครื่องลมไม้", labelEn: "Woodwind", value: "4" },
    { labelTh: "เครื่องลมทองเหลือง", labelEn: "Brass", value: "5" },
    {
      labelTh: "ไซเลนท์ไวโอลิน, ไซเลนท์วิโอลา, ไซเลนท์เชลโล, ไซเลนท์ดับเบิลเบส",
      labelEn: "Silent Violin, Silent Viola, Silent Cello, Silent Double Bass",
      value: "6",
    },
    {
      labelTh: "ไวโอลินไฟฟ้า, วิโอลาไฟฟ้า, เชลไฟฟ้า, ดับเบิลเบสไฟฟ้า",
      labelEn:
        "Electric Violin, Electric Viola, Electric Cello, Electric Double Bass",
      value: "7",
    },
    { labelTh: "ดิจิตอลแซกโซโฟน", labelEn: "Digital Saxophone", value: "8" },
    { labelTh: "HD-300", labelEn: "HD-300", value: "9" },
    {
      labelTh: "เครื่องเพอร์คัชชัน",
      labelEn: "Percussion Instruments",
      value: "10",
    },
    {
      labelTh: "เครื่องสายอคูสติก",
      labelEn: "Acoustic String Instruments",
      value: "11",
    },
    { labelTh: "ขลุ่ย", labelEn: "Recorder", value: "12" },
    { labelTh: "เปียนิกา", labelEn: "Recorder", value: "13" },
    { labelTh: "เวโนวา", labelEn: "Recorder", value: "14" },
  ],
  3: [
    { labelTh: "กีต้าร์โปร่ง", labelEn: "Acoustic Guitar", value: "15" },
    { labelTh: "กลองอคูสติกทุกรุ่น", labelEn: "Acoustic Drum", value: "16" },
    {
      labelTh: "กีต้าร์โปร่งที่มีภาคไฟฟ้า",
      labelEn: "Acoustic Guitar with Electric Parts",
      value: "17",
    },
    { labelTh: "ตู้แอมป์", labelEn: "Amplifier Cabinet", value: "18" },
    {
      labelTh: "ดิจิตอล เปียโน/ อีเล็กทรอนิส์ คีย์บอร์ด",
      labelEn: "Digital Piano/Electronic Keyboard",
      value: "19",
    },
    { labelTh: "ซินธิไซเซอร์", labelEn: "Synthesizer", value: "20" },
    { labelTh: "กลองไฟฟ้าทุกรุ่น", labelEn: "Electronic Drum", value: "21" },
    { labelTh: "มัลติเอฟเฟค", labelEn: "Multi-Effects", value: "22" },
    { labelTh: "กีตาร์ไวเลส", labelEn: "Wireless Guitar", value: "23" },
  ],
  4: [
    {
      labelTh: "สินค้าอิเล็กทรอนิกส์",
      labelEn: "Electronic Products",
      value: "24",
    },
    {
      labelTh: "ลำโพงแบบมีภาคขยาย",
      labelEn: "Speakers with Amplification",
      value: "25",
    },
    {
      labelTh: "ตู้ลำโพงและดอกลำโพง",
      labelEn: "Speaker cabinets and Loudspeakers",
      value: "26",
    },
  ],
};

export const PRODUCT_BOOKING_OPTIONS = [
  { labelTh: "เปียโนอคูสติก", labelEn: "Acoustic Piano", value: "1" },
  { labelTh: "เปียโนไฮบริด", labelEn: "Hybrid Piano", value: "2" },
  { labelTh: "เปียโนไฟฟ้า", labelEn: "Electronic Piano", value: "3" },
  { labelTh: "เครื่องลมไม้", labelEn: "Woodwind", value: "4" },
  { labelTh: "เครื่องลมทองเหลือง", labelEn: "Brass", value: "5" },
  {
    labelTh: "ไซเลนท์ไวโอลิน, ไซเลนท์วิโอลา, ไซเลนท์เชลโล, ไซเลนท์ดับเบิลเบส",
    labelEn: "Silent Violin, Silent Viola, Silent Cello, Silent Double Bass",
    value: "6",
  },
  {
    labelTh: "ไวโอลินไฟฟ้า, วิโอลาไฟฟ้า, เชลไฟฟ้า, ดับเบิลเบสไฟฟ้า",
    labelEn:
      "Electric Violin, Electric Viola, Electric Cello, Electric Double Bass",
    value: "7",
  },
  { labelTh: "ดิจิตอลแซกโซโฟน", labelEn: "Digital Saxophone", value: "8" },
  { labelTh: "HD-300", labelEn: "HD-300", value: "9" },
  {
    labelTh: "เครื่องเพอร์คัชชัน",
    labelEn: "Percussion Instruments",
    value: "10",
  },
  {
    labelTh: "เครื่องสายอคูสติก",
    labelEn: "Acoustic String Instruments",
    value: "11",
  },
  { labelTh: "ขลุ่ย", labelEn: "Recorder", value: "12" },
  { labelTh: "เปียนิกา", labelEn: "Recorder", value: "13" },
  { labelTh: "เวโนวา", labelEn: "Recorder", value: "14" },
  { labelTh: "กีต้าร์โปร่ง", labelEn: "Acoustic Guitar", value: "15" },
  { labelTh: "กลองอคูสติกทุกรุ่น", labelEn: "Acoustic Drum", value: "16" },
  {
    labelTh: "กีต้าร์โปร่งที่มีภาคไฟฟ้า",
    labelEn: "Acoustic Guitar with Electric Parts",
    value: "17",
  },
  { labelTh: "ตู้แอมป์", labelEn: "Amplifier Cabinet", value: "18" },
  {
    labelTh: "ดิจิตอล เปียโน/ อีเล็กทรอนิส์ คีย์บอร์ด",
    labelEn: "Digital Piano/Electronic Keyboard",
    value: "19",
  },
  { labelTh: "ซินธิไซเซอร์", labelEn: "Synthesizer", value: "20" },
  { labelTh: "กลองไฟฟ้าทุกรุ่น", labelEn: "Electronic Drum", value: "21" },
  { labelTh: "มัลติเอฟเฟค", labelEn: "Multi-Effects", value: "22" },
  { labelTh: "กีตาร์ไวเลส", labelEn: "Wireless Guitar", value: "23" },
  {
    labelTh: "สินค้าอิเล็กทรอนิกส์",
    labelEn: "Electronic Products",
    value: "24",
  },
  {
    labelTh: "ลำโพงแบบมีภาคขยาย",
    labelEn: "Speakers with Amplification",
    value: "25",
  },
  {
    labelTh: "ตู้ลำโพงและดอกลำโพง",
    labelEn: "Speaker cabinets and Loudspeakers",
    value: "26",
  },
];

export const RENDER_PRODUCT_BOOKING = (key) => {
  switch (key) {
    case "1":
      return {
        labelTh: "เปียโนอคูสติก",
        labelEn: "Acoustic Piano",
      };
    case "2":
      return {
        labelTh: "เปียโนไฮบริด",
        labelEn: "Hybrid Piano",
      };
    case "3":
      return { labelTh: "เปียโนไฟฟ้า", labelEn: "Electronic Piano" };
    case "4":
      return { labelTh: "เครื่องลมไม้", labelEn: "Woodwind" };
    case "5":
      return { labelTh: "เครื่องลมทองเหลือง", labelEn: "Brass" };
    case "6":
      return {
        labelTh:
          "ไซเลนท์ไวโอลิน, ไซเลนท์วิโอลา, ไซเลนท์เชลโล, ไซเลนท์ดับเบิลเบส",
        labelEn:
          "Silent Violin, Silent Viola, Silent Cello, Silent Double Bass",
      };
    case "7":
      return {
        labelTh: "ไวโอลินไฟฟ้า, วิโอลาไฟฟ้า, เชลไฟฟ้า, ดับเบิลเบสไฟฟ้า",
        labelEn:
          "Electric Violin, Electric Viola, Electric Cello, Electric Double Bass",
      };
    case "8":
      return {
        labelTh: "ดิจิตอลแซกโซโฟน",
        labelEn: "Digital Saxophone",
      };
    case "9":
      return {
        labelTh: "HD-300",
        labelEn: "HD-300",
      };
    case "10":
      return {
        labelTh: "เครื่องเพอร์คัชชัน",
        labelEn: "Percussion Instruments",
      };
    case "11":
      return {
        labelTh: "เครื่องสายอคูสติก",
        labelEn: "Acoustic String Instruments",
      };
    case "12":
      return {
        labelTh: "ขลุ่ย",
        labelEn: "Recorder",
      };
    case "13":
      return {
        labelTh: "เปียนิกา",
        labelEn: "Recorder",
      };
    case "14":
      return {
        labelTh: "เวโนวา",
        labelEn: "Recorder",
      };
    case "15":
      return {
        labelTh: "กีต้าร์โปร่ง",
        labelEn: "Acoustic Guitar",
      };
    case "16":
      return {
        labelTh: "กลองอคูสติกทุกรุ่น",
        labelEn: "Acoustic Drum",
      };
    case "17":
      return {
        labelTh: "กีต้าร์โปร่งที่มีภาคไฟฟ้า",
        labelEn: "Acoustic Guitar with Electric Parts",
      };
    case "18":
      return {
        labelTh: "ตู้แอมป์",
        labelEn: "Amplifier Cabinet",
      };
    case "19":
      return {
        labelTh: "ดิจิตอล เปียโน/ อีเล็กทรอนิส์ คีย์บอร์ด",
        labelEn: "Digital Piano/Electronic Keyboard",
      };
    case "20":
      return {
        labelTh: "ซินธิไซเซอร์",
        labelEn: "Synthesizer",
      };
    case "21":
      return {
        labelTh: "กลองไฟฟ้าทุกรุ่น",
        labelEn: "Electronic Drum",
      };
    case "22":
      return {
        labelTh: "มัลติเอฟเฟค",
        labelEn: "Multi-Effects",
      };
    case "23":
      return {
        labelTh: "กีตาร์ไวเลส",
        labelEn: "Wireless Guitar",
      };
    case "24":
      return {
        labelTh: "สินค้าอิเล็กทรอนิกส์",
        labelEn: "Electronic Products",
      };
    case "25":
      return {
        labelTh: "ลำโพงแบบมีภาคขยาย",
        labelEn: "Speakers with Amplification",
      };
    case "26":
      return {
        labelTh: "ตู้ลำโพงและดอกลำโพง",
        labelEn: "Speaker cabinets and Loudspeakers",
      };

    default:
      break;
  }
};
