import styled from "styled-components";

export const InputRegisTypeWidgetStyled = styled.div`
  margin-bottom: 30px;
  .input_regis_type1_container {
    display: flex;
    column-gap: 12px;
    .btn_inr_item {
      flex: 1;
      border-radius: 9px;
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_4}21;
      padding: 12px 10px;
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.PURPLE_3}21;
      cursor: pointer;
      &.active {
        border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_4}73;
        background: ${({ theme }) => theme.COLORS.PURPLE_3}73;
      }
      .inr_icon_wrap {
        margin-left: 15px;
        margin-right: 12px;
      }
      .inr_label {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
    .btn_inr_col {
      flex: 1;
    }
  }

  .input_regis_type2_container {
    .btn_inr_item {
      border-radius: 9px;
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_4}21;
      padding: 12px 10px;
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.PURPLE_3}21;
      cursor: pointer;
      &.mb_10 {
        margin-bottom: 10px;
      }
      &.active {
        border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_4}73;
        background: ${({ theme }) => theme.COLORS.PURPLE_3}73;
      }
      &.add {
        padding: 12px 10px 12px 26px;
      }
      .inr_icon_wrap {
        margin-left: 15px;
        margin-right: 12px;
      }
      .inr_label {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }

  .input_regis_type3_container {
    margin: auto;
    width: fit-content;
    .btn_history {
      max-width: 317px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      width: 100%;
      padding: 0 20px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      .content {
        display: flex;
        align-items: center;
        column-gap: 15px;
      }
      .arrow_next {
        font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      }
    }
    .line_between {
      width: 317px;
      margin: 20px 0;
      height: 1px;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      opacity: 0.6;
    }
  }
`;

export const FormContainer = styled.div`
  border-radius: 12px;
  padding: 30px 36px;
  max-width: 370px;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  color: ${({ theme }) => theme.COLORS.GRAY_2};
  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
`;
