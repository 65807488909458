import React from "react";

export default function renderIcon({
  width = 39.002,
  height = 47.062,
  color = "#fff",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 39 41"
    >
      <path
        id="Union_26"
        data-name="Union 26"
        d="M8,41a8.009,8.009,0,0,1-8-8V11.265a8.01,8.01,0,0,1,8-8H9.4V6.081a2.263,2.263,0,1,0,4.527,0V3.265H26.464V6.081a2.264,2.264,0,0,0,4.527,0V3.265H31a8.01,8.01,0,0,1,8,8V33a8.009,8.009,0,0,1-8,8ZM2.437,22.133h.191A5.022,5.022,0,0,0,2.437,23.5V33.46a5.005,5.005,0,0,0,5,5H31.562a5.005,5.005,0,0,0,5-5V23.5a5.04,5.04,0,0,0-.19-1.372h.19V14.876H2.437ZM27.16,6.053V1.567a1.567,1.567,0,0,1,3.134,0V6.053a1.567,1.567,0,0,1-3.134,0Zm-17.062,0V1.567a1.567,1.567,0,1,1,3.134,0V6.053a1.567,1.567,0,1,1-3.134,0Z"
        fill={color}
      />
    </svg>
  );
}
